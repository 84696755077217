<template>
  <div>
    <section class="stage-panel mb-m">
      <h1 class="stage-panel__title">
        <span class="text-size-h1 text-bold"
          ><BaseIcon glyph="star" />Этап {{ stageNumber }}</span
        >&nbsp; <span v-if="stageLabel">{{ stageLabel }}</span>
      </h1>
    </section>

    <KeepAlive>
      <Component
        :is="currentComponent"
        :stage-number="stageNumber"
        :activities="activities" />
    </KeepAlive>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { INDIVIDUAL_STAGE, TEAM_STAGE } from "@/constants";
import StageIndividual from "@/components/user/StageIndividual";
import StageTeam from "@/components/user/StageTeam";

export default {
  name: "UserStage",
  components: { StageIndividual, StageTeam },
  props: {
    stageNumber: {
      type: Number,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: `${this.stageNumber} Этап`,
    };
  },
  computed: {
    ...mapState({
      profiles: (state) => state.profile.profiles,
    }),
    ...mapGetters({
      user: "user/user",
      isStudent: "participant/isStudent",
    }),
    activities() {
      const { currentStage } = this;
      if (!currentStage) return [];
      return this.$store.getters["participant/activitiesByStage"](currentStage);
    },
    currentStage() {
      const { isStudent, stageNumber } = this;
      if (isStudent && stageNumber === 1) {
        return TEAM_STAGE;
      } else if (!isStudent && stageNumber === 1) {
        return INDIVIDUAL_STAGE;
      } else if (!isStudent && stageNumber === 2) {
        return TEAM_STAGE;
      }
      return undefined;
    },
    stageLabel() {
      const { currentStage } = this;
      if (currentStage === INDIVIDUAL_STAGE) return "индивидуальный";
      if (currentStage === TEAM_STAGE) return "командный";
      return undefined;
    },
    currentComponent() {
      const { currentStage } = this;
      if (currentStage === TEAM_STAGE) {
        return "StageTeam";
      } else if (currentStage === INDIVIDUAL_STAGE) {
        return "StageIndividual";
      }
      return "div";
    },
  },
  async created() {
    try {
      await this.$store.dispatch("participant/getScores");
    } catch (error) {
      console.log("error", error);
    }
  },
};
</script>

<style lang="less" scoped>
.stage-panel {
  padding: 40px 20px;
  background-color: @grey-blue;
  border-radius: @radius-m;
  color: #fff;

  &__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & ::v-deep .base-icon {
      margin-top: -0.2em;
      margin-right: 0.4em;
    }
  }
}
</style>
